import React from 'react'
import java from '../assets/images/java.svg';

const HeaderJava = (props) => (
    <header id="header" className="alt">
        <span className="logo"><img src={java} alt="Python vs Java" /></span>
        <h1>Python vs Java</h1>
        <h2>Comparison of Python and Java.</h2>
    </header>
)

export default HeaderJava
